body {
  padding: 0;
  margin: 0;
}

.subheader {
  background-color: #f9f9f9;
  padding-top: 40px;
  padding-bottom: 30px;
}

.subheaderTitle {
  color: #6b7cff;
  line-height: 0.9 !important;
  font-size: 30px;
}

.subheaderSubtitle {
  font-size: 20px;
}

.prefix {
  color: #c3c3c3;
}

.date {
  color: #6b7cff;
}

.subheaderBackButton {
  border: 2px solid #6b7cff;
  padding-bottom: 34px !important;
}

.createAppointmentTitle {
  padding-top: 25px;
}

.ant-input-affix-wrapper {
  padding: 4px 4px !important;
}

.buttonFile {
  background-color: #6b7cff;
}

.ant-select-selector {
  border-radius: 4px !important;
  border: 1px solid #dde1e9;
}

.ant-select-selection-item {
  color: #949494;
}
